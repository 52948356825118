/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import React from 'react'
import classNames from 'classnames'

export const CenterVertical = ({ width='md', className='', children }) => (
    <div className="center-vertical-container">
        <div className={classNames('center-vertical', 'shadow', 'container', width, className)}>
            { children }
        </div>
    </div>
)
