/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

export const joinStrings = (values, joinString) => (
    values.filter(s => s?.length > 0).join(joinString)
)

export const imageUrl = (value) => {
    let url
    if (typeof value === 'string' && value.length > 0) {
        if (value.indexOf('/app') === 0) {
            url = value
        } else {
            url = `/i/${value}`
        }
    } else if (value) {
        url = value.preview
    }
    return url
}

export const makeUrl = (value) => {
    let url = value
    if (value.indexOf('http') !== 0) {
        url = `https://${value}`
    }
    return url
}

export const extractFiles = (values, fileFields) => {
    const files = {}
    for (let key in values) {
        if (fileFields.indexOf(key) >= 0) {
            if (values[key] instanceof File) {
                files[key] = values[key]
            }
            delete values[key]
        }
    }
    return [ values, files ]
}

export const isAndroid = () => /android/i.test(navigator.userAgent)
export const isIOS = () => /(ipad)|(iphone)|(ipod)/i.test(navigator.userAgent)
export const isSafari = () => /safari/i.test(navigator.userAgent) && !/CriOS/i.test(navigator.userAgent)
export const isFirefox = () => /firefox/i.test(navigator.userAgent)
