/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2023 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import React from 'react'
import { useNavigate } from 'react-router-dom'
import server from '../core/server'

const LaunchPage = () => {
    const navigate = useNavigate()

    React.useEffect(() => {
        const url = localStorage.getItem('launchUrl')
        if (url) {
            navigate(url, { replace: true })
        } else {
            server.listCards().then(data => {
                const cardId = data.cards?.[0].id
                if (cardId) {
                    const url = `/app/qr/${cardId}`
                    localStorage.setItem('launchUrl', url)
                    navigate(url, { replace: true })
                } else {
                    throw Error()
                }

            }).catch(() => {
                navigate('app', { replace: true })
            })
        }
    }, [ navigate ])


    return null
}

export default LaunchPage
