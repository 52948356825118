/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../i18n'

import React from 'react'
import Button from 'react-bootstrap/Button'

import { useCardList } from '../core/server'

import { LinkButton } from './router'
import templates from './templates'
import { Spinner } from './uielements'

const CardPreview = ({ card, onDelete }) => {
    const template = templates[card.template] || templates.standard
    return (
        <div className="CardPreview">
            <h3>{ card.name }</h3>
            { template.textPreview(card.values || {}) }
            <div className="mt-auto">
                <LinkButton className="w-100" variant="primary" href={`/app/share/${card.id}`}>{ i18n.t('Share') }</LinkButton>
            </div>
            <div className="mt-3 align-self-center">
                <LinkButton variant="primary" href={`/app/card/${card.id}`}>{ i18n.t('Edit') }</LinkButton>&nbsp;
                <Button variant="danger" onClick={() => onDelete(card)}>{ i18n.t('Delete') }</Button>
            </div>
        </div>
    )
}

const AddCard = () => (
    <div className="CardPreview justify-content-center align-items-center">
        <LinkButton variant="success" href="/app/card/create">{ i18n.t('New Card') }</LinkButton>
    </div>
)

const CardList = ({ onDelete }) => {
    const { data, isLoading } = useCardList()

    return isLoading ? <Spinner /> : (
        <div className="CardList container">
            <div className="row">
                { data?.cards?.map((card) => (
                    <div className="col-sm-4" key={'card-'+card.id}>
                        <CardPreview card={card} onDelete={onDelete}/>
                    </div>
                ))}

                { data?.cards?.length === 0 && (
                    <div className="col-sm-4">
                        <AddCard />
                    </div>
                )}
            </div>
        </div>
    )
}

export default CardList