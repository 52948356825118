/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../i18n'

import React from 'react'
import { useSWRConfig } from 'swr'

import serverapi from '../core/server'
import { Form, TextField, RecaptchaField, Submit } from '../widgets/forms'
import { CenterVertical } from '../widgets/layouts'
import PlanSelectField from '../widgets/PlanSelectField'
import { LinkButton } from '../widgets/router'

const onValidate = values => {
    const errors = {}
    if (!values.plan) {
        errors.plan = i18n.t('Please select a subscription plan')
    }
    if (!values.email) {
        errors.email = i18n.t('Please enter email address')
    }
    if (!values.password) {
        errors.password = i18n.t('Please enter password')
    }
    else if (values.password !== values.confirmPassword) {
        errors.confirmPassword = i18n.t('Passwords do not match')
    }
    if (!values.captcha) {
        errors.captcha = i18n.t('Please check this box')
    }
    return errors
}

const initialValues = {
    plan: '',
    email: '',
    firstname: '',
    lastname: '',
    password: '',
    confirmPassword: '',
    captcha: '',
}

const RegisterPage = () => {
    const { mutate } = useSWRConfig()

    const onSubmit = ({ values, setSubmitting, setErrors }) => {
        setSubmitting(true)
        serverapi.register(values, mutate).catch(err => {
            if (err.error === 1000) {
                setErrors({ email: i18n.t('Email address already in use') })
            } else if (err.error === 1001) {
                setErrors({ password: i18n.t('Password too weak') })
            } else if (err.error === 1002) {
                setErrors({ captcha: i18n.t('Invalid captcha') })
            }
        }).finally(() => {
            setSubmitting(false)
        })
    }

    return (
        <CenterVertical className="mt-5" width="sm">
            <div className="row justify-content-center py-5 px-3">
                <div className="col text-center">
                    <img className="mb-3" src="/app/icon256.png" width="128" height="128" alt="Logo"/>

                    <Form initialValues={initialValues} onValidate={onValidate} onSubmit={onSubmit}>
                        <PlanSelectField name="plan" />
                        <TextField name="email" type="text" placeholder={i18n.t('Email')} autoFocus />
                        <TextField name="firstname" type="text" placeholder={i18n.t('First Name')} />
                        <TextField name="lastname" type="text" placeholder={i18n.t('Last Name')} />
                        <TextField name="password" type="password" placeholder={i18n.t('Password')} />
                        <TextField name="confirmPassword" type="password" placeholder={i18n.t('Confirm Password')} />
                        <RecaptchaField name="captcha" />
                        <Submit className="w-100 mb-4">{ i18n.t('Create Account') }</Submit>
                        <LinkButton variant="link" size="sm" href="..">{ i18n.t('Cancel') }</LinkButton>
                    </Form>
                </div>
            </div>
        </CenterVertical>
    )
}

export default RegisterPage
