/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

//import i18n from '../i18n'

import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { useProfile } from './server'

import LoadingPage from '../widgets/LoadingPage'
import AppRootLayout from '../widgets/AppRootLayout'
import PublicRootLayout from '../widgets/PublicRootLayout'

import BillingPage from '../pages/BillingPage'
import CardEditPage from '../pages/CardEditPage'
import CardQRPage from '../pages/CardQRPage'
import CardSharePage from '../pages/CardSharePage'
import CardViewPage from '../pages/CardViewPage'
import ChangePaymentPage from '../pages/ChangePaymentPage'
import ChangePaymentConfirmPage from '../pages/ChangePaymentConfirmPage'
import ChangePlanPage from '../pages/ChangePlanPage'
import HomePage from '../pages/HomePage'
import LaunchPage from '../pages/LaunchPage'
import LoginPage from '../pages/LoginPage'
import OnboardPaymentPage from '../pages/OnboardPaymentPage'
import ProfilePage from '../pages/ProfilePage'
import RegisterPage from '../pages/RegisterPage'
import ResetPasswordPage from '../pages/ResetPasswordPage'
import ResetPasswordConfirmPage from '../pages/ResetPasswordConfirmPage'
import ResetPasswordSuccessPage from '../pages/ResetPasswordSuccessPage'

const publicRoutes = [
    { index: true, element: <LoginPage/> },
    { path: 'launch', element: <LaunchPage/> },
    { path: 'register', element: <RegisterPage/> },
    { path: 'reset-password', element: <ResetPasswordPage/> },
    { path: 'reset-password-confirm', element: <ResetPasswordConfirmPage/> },
    { path: 'reset-password-success', element: <ResetPasswordSuccessPage/> },
]

const appRoutes = [
    { index: true, element: <HomePage /> },
    { path: 'launch', element: <LaunchPage /> },
    { path: 'profile', element: <ProfilePage /> },
    { path: 'billing', element: <BillingPage /> },
    { path: 'billing/payment', element: <ChangePaymentPage /> },
    { path: 'billing/payment/confirm', element: <ChangePaymentConfirmPage /> },
    { path: 'billing/changeplan', element: <ChangePlanPage /> },
    { path: 'card/:cardId', element: <CardEditPage /> },
    { path: 'card/create', element: <CardEditPage /> },
    { path: 'share/:cardId', element: <CardSharePage /> },
]

const RootRoutes = ({ mainRoutes, MainLayout }) => (
    <Routes>
        <Route path="/app/c/:cardId" element={<CardViewPage />} />
        <Route path="/app/qr/:cardId" element={<CardQRPage />} />
        <Route path="/app" element={<MainLayout/>}>
            { mainRoutes.map((props, i) => <Route key={'route'+i} { ...props }/>) }
            <Route path="*" element={<Navigate to="/app" replace={true} />} />
        </Route>
        <Route path="*" element={<Navigate to="/app" replace={true} />} />
    </Routes>
)

export const useRoutes = () => {
    const [ initialLoad, setInitialLoad ] = React.useState(true)
    const { user, isLoading } = useProfile()

    React.useEffect(() => {
        if (initialLoad && !isLoading) {
            setInitialLoad(false)
        }
    }, [ initialLoad, isLoading ])

    if (isLoading && initialLoad) {
        return <LoadingPage/>
    } else if (user) {
        if (user.billingStatus === 1) {
            return <OnboardPaymentPage />
        }
        return <RootRoutes mainRoutes={appRoutes} MainLayout={AppRootLayout} />
    }
    return <RootRoutes mainRoutes={publicRoutes} MainLayout={PublicRootLayout} />
}
