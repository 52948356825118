/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2023 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../i18n'

import React from 'react'
import { Link } from 'react-router-dom'

import server from '../core/server'
import { CenterVertical } from '../widgets/layouts'
import LoadingPage from '../widgets/LoadingPage'
import { PaymentForm } from '../widgets/Stripe'

const ChangePaymentPage = () => {
    const [ creatingSetupIntent, setCreatingSetupIntent ] = React.useState(false)
    const [ clientSecret, setClientSecret ] = React.useState(null)

    React.useEffect(() => {
        if (!clientSecret && !creatingSetupIntent) {
            setCreatingSetupIntent(true)
            server.startChangePayment().then((data) => {
                setClientSecret(data.clientSecret)
                setCreatingSetupIntent(false)
            })
        }
    }, [ clientSecret, setClientSecret, creatingSetupIntent, setCreatingSetupIntent ])
    
    return (
        <CenterVertical className="mt-5" width="sm">
            <div className="row justify-content-center py-5 px-3">
                <div className="col">
                    <h2 className="mb-4">{ i18n.t('Update Payment Method') }</h2>
                    { creatingSetupIntent ? (
                        <LoadingPage />
                    ) : clientSecret && (
                        <>
                            <PaymentForm
                                clientSecret={clientSecret}
                                returnUrl='https://app.digi-cards.biz/app/billing/payment/confirm'
                                setupIntent
                                />
                            <div className="mt-5">
                                <Link to=".."><small>{ i18n.t('Back') }</small></Link>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </CenterVertical>
    )
}

export default ChangePaymentPage
