/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../i18n'

import React from 'react'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Reaptcha from 'reaptcha'
import { useDropzone } from 'react-dropzone'
import classNames from 'classnames'

import { imageUrl } from '../core/utils'

const FormContext = React.createContext({})

export const Form = ({ initialValues, onValidate, onSubmit, children }) => {
    const [ values, setValues ] = React.useState(initialValues)
    const [ errors, setErrors ] = React.useState({})
    const [ submitting, setSubmitting ] = React.useState(false)

    const form = {
        values,
        setValues,
        errors,
        setErrors,
        submitting,
        setSubmitting,
        onValidate,
        onSubmit
    }

    return (
        <FormContext.Provider value={form}>
            { values && children }
        </FormContext.Provider>
    )
}

const submitForm = ({ values, setErrors, setSubmitting, onValidate, onSubmit }) => {
    if (onValidate) {
        const errors = onValidate(values)
        setErrors(errors)
        if (Object.keys(errors).length > 0) {
            return
        }
    }

    onSubmit({ values, setErrors, setSubmitting })
}

export const useForm = () => React.useContext(FormContext)

export const useField = (name) => {
    const { values, setValues, errors, setErrors, submitting } = useForm()
    return {
        values,
        value: values[name],
        setValue: v => setValues({ ...values, [name]: v }),
        error: errors[name],
        setError: e => setErrors({ ...errors, [name]: e }),
        submitting
    }
}

export const Input = ({ name, className='form-control', ...props }) => {
    const { value, error, setValue, submitting } = useField(name)
    return (
        <input
            className={className + (error ? ' is-invalid' : '')}
            type="text"
            name={name}
            value={value}
            onChange={(ev) => setValue(ev.target.value)}
            disabled={submitting}
            { ...props}
            />
    )
}

export const Field = ({
    name,
    label,
    labelClass='form-label',
    labelFirst=true,
    helpText,
    containerClass='mb-3',
    containerStyle,
    children,
    ...props
}) => {
    const { error } = useField(name)
    const Label = () => label && <label className={labelClass}>{ label }</label>

    return (
        <div className={containerClass} style={containerStyle}>
            { labelFirst && <Label /> }
            { children(props) }
            { !labelFirst && <Label /> }
            { error && <div className="invalid-feedback">{ error }</div> }
            { helpText && <div className="form-text">{ helpText }</div> }
        </div>
    )
}

export const TextField = ({ name, ...props }) => (
    <Field name={name} { ...props }>
        {(props) => (
            <Input className="form-control" name={name} { ...props }/>
        )}
    </Field>
)

export const CheckField = ({ name, type='checkbox', ...props }) => (
    <TextField name={name} labelClass="form-check-label" labelFirst={false} { ...props }>
        {(props) => (
            <Input className="form-check-input" name={name} type={type} { ...props } />
        )}
    </TextField>
)

export const ChoiceField = ({ name, choices, ...props }) => {
    const { value, setValue } = useField(name)
    return (
        <Field name={name} { ...props }>
            {(props) => (
                <select className="form-select" value={value} onChange={setValue} { ...props }>
                    { choices?.map(([ v, label ]) => (
                        <option key={'opt-'+v} value={v}>{ label }</option>
                    ))}
                </select>
            )}
        </Field>
    )
}

export const ColorField = ({ name, type='color', ...props }) => (
    <Field name={name} { ...props }>
        {(props) => (
            <Input className="form-control form-control-color" name={name} type={type} { ...props } />
        )}
    </Field>
)

export const ImageField = ({ name, width, height, backgroundName, ...props }) => {
    const { values, value, setValue } = useField(name)
    const { getRootProps, getInputProps, isDragAccept } = useDropzone({
        multiple: false,
        accept: { 'image/*': [] },
        onDrop: files => {
            if (value?.preview) {
                URL.revokeObjectURL(value.preview)
            }
            setValue(Object.assign(files[0], { preview: URL.createObjectURL(files[0]) }))
        }
    })

    React.useEffect(() => () => value && URL.revokeObjectURL(value.preview), [ value ])

    let imageSrc = imageUrl(value)
    let backgroundColor
    if (backgroundName) {
        backgroundColor = values[backgroundName]
    }

    return (
        <Field name={name} { ...props }>
            {() => (
                <div className="ImageField">
                    <div {...getRootProps({ className: classNames('dropzone', { accepted: isDragAccept })})}>
                        <input {...getInputProps()} />
                        <p>{ i18n.t('Drag and drop an image here or click to select file') }</p>
                        <p>{ i18n.t('Dimensions') }: { width } x { height }</p>
                    </div>
                    <div className="preview" style={{ backgroundColor }}>
                        { imageSrc && (
                            <div style={{ backgroundImage: `url(${imageSrc})`, width, height }} />
                        )}
                    </div>
                </div>
            )}
        </Field>
    )
}

export const RecaptchaField = ({ name, ...props }) => {
    const { error, setValue } = useField(name)

    return (
        <Field name={name} { ...props }>
            {() => (
                <Reaptcha
                    className={error ? 'is-invalid' : ''}
                    sitekey="6Lc-vGYjAAAAAGlpEuRHb6c_GTDP3srpKBlCfzoE"
                    onVerify={setValue}
                    />
            )}
        </Field>
    )
}

export const Submit = ({ children, ...props }) => {
    const form = useForm()
    return (
        <Button
            onClick={() => submitForm(form)}
            disabled={form.submitting}
            { ...props }
            >
            { children }
            { form.submitting && <>&nbsp;<Spinner animation="border" role="status" size="sm"/></>}
        </Button>
    )
}
