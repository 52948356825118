/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2023 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../i18n'

import React from 'react'
import { useSWRConfig } from 'swr'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner'

import plans from '../core/plans'
import server, { useSubscription } from '../core/server'
import { CenterVertical } from '../widgets/layouts'
import LoadingPage from '../widgets/LoadingPage'
import { PaymentForm } from '../widgets/Stripe'

const OnboardPaymentPage = () => {
    const { mutate } = useSWRConfig()
    const { subscription, isLoading } = useSubscription()
    const [ deleting, setDeleting ] = React.useState(false)

    if (subscription && subscription.billingStatus > 1) {
        window.location.reload()
    }

    const plan = plans.find(x => subscription?.billingPlan === x.code)

    const onCancel = () => {
        setDeleting(true)
        server.deleteAccount(mutate).finally(() => setDeleting(false))
    }

    return (
        <CenterVertical className="mt-5" width="sm">
            <div className="row justify-content-center py-5 px-3">
                <div className="col text-center">
                    <img className="mb-3" src="/app/icon256.png" width="128" height="128" alt="Logo"/>
                    { isLoading ? (
                        <LoadingPage />
                    ) : subscription?.billingStatus === 1 && plan && (
                        <>
                            <div className="d-flex flex-column align-items-center mt-2 mb-4">
                                <Card className="">
                                    <Card.Body>
                                        <Card.Title>{ plan.label }</Card.Title>
                                        <Card.Text>{ plan.desc }</Card.Text>
                                    </Card.Body>
                                    <Card.Footer className="fw-bold">{ plan.price }</Card.Footer>
                                </Card>
                            </div>

                            <PaymentForm clientSecret={subscription.clientSecret} disabled={deleting} />

                            <div className="mt-5">
                                <Button variant="link" onClick={onCancel}>
                                    <small>{ i18n.t('Cancel Registration') }</small>
                                    { deleting && <>&nbsp;<Spinner animation="border" role="status" size="sm"/></>}
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </CenterVertical>
    )
}

export default OnboardPaymentPage
