/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../../i18n'

import { Input } from '../forms'
import { joinStrings } from '../../core/utils'

export const Row = ({ children }) => <div className="row g-3 mb-3">{ children }</div>

export const Field = ({ size, label, children }) => (
    <div className={`col-${size}`}>
        <label className="form-label">{ label }</label>
        { children }
    </div>
)

export const InputField = ({ size, label, name, ...props }) => (
    <Field size={size} label={label}>
        <Input name={ name } type="text" className="form-control" { ...props } />
    </Field>
)

class BaseCard {
    name = ''
    templateFields = {}
    fileFields = []

    baseFields = {
        firstname: '',
        middlename: '',
        lastname: '',
        suffix: '',
        email: '',
        officephone: '',
        mobilephone: '',
        street: '',
        city: '',
        region: '',
        zipcode: '',
        country: '',
        organization: '',
        title: '',
        website: '',
        website2: '',
        website3: '',
        linkedin: '',
        instagram: '',
        facebook: '',
        twitter: '',
        youtube: '',
        tiktok: '',
    }

    getValues(cardValues) {
        const values = Object.assign({}, this.baseFields, this.templateFields, cardValues)
        values.fullname = joinStrings([ values.firstname, values.middlename, values.lastname, values.suffix ], ' ')
        values.address = joinStrings([ values.street, values.city, values.region, values.zipcode, values.country ], ', ')
        return values
    }

    editFields() {
        return (
            <>
                <Row>
                    <InputField size={6} name="firstname" label={ i18n.t('First') } />
                    <InputField size={6} name="middlename" label={ i18n.t('Middle') } />
                </Row>
                <Row>
                    <InputField size={6} name="lastname" label={ i18n.t('Last') } />
                    <InputField size={6} name="suffix" label={ i18n.t('Suffix') } />
                </Row>
                <Row>
                    <InputField size={6} name="organization" label={ i18n.t('Company') } />
                    <InputField size={6} name="title" label={ i18n.t('Title') } />
                </Row>
                <Row>
                    <InputField size={12} name="email" label={ i18n.t('Email') } type="email" />
                </Row>
                <Row>
                    <InputField size={6} name="mobilephone" label={ i18n.t('Mobile Phone') } type="tel" />
                    <InputField size={6} name="officephone" label={ i18n.t('Office Phone') } type="tel" />
                </Row>
                <Row>
                    <InputField size={12} name="street" label={ i18n.t('Address') } />
                </Row>
                <Row>
                    <InputField size={6} name="city" label={ i18n.t('City') } />
                    <InputField size={3} name="region" label={ i18n.t('State') } />
                    <InputField size={3} name="zipcode" label={ i18n.t('ZIP Code') } />
                </Row>
                <Row>
                    <InputField size={12} name="website" label={ i18n.t('Company Website') } />
                </Row>
                <Row>
                    <InputField size={12} name="website2" label={ i18n.t('Other Website 1') } />
                </Row>
                <Row>
                    <InputField size={12} name="website3" label={ i18n.t('Other Website 2') } />
                </Row>
                <Row>
                    <InputField size={12} name="linkedin" label={ i18n.t('Linkedin Link') } />
                </Row>
                <Row>
                    <InputField size={12} name="instagram" label={ i18n.t('Instagram Link') } />
                </Row>
                <Row>
                    <InputField size={12} name="facebook" label={ i18n.t('Facebook Link') } />
                </Row>
                <Row>
                    <InputField size={12} name="twitter" label={ i18n.t('Twitter Link') } />
                </Row>
                <Row>
                    <InputField size={12} name="youtube" label={ i18n.t('Youtube Link') } />
                </Row>
                <Row>
                    <InputField size={12} name="tiktok" label={ i18n.t('Tiktok Link') } />
                </Row>
            </>
        )
    }
}

export default BaseCard
