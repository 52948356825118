/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2023 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../i18n'

import React from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'

import server from '../core/server'
import { CenterVertical } from '../widgets/layouts'
import LoadingPage from '../widgets/LoadingPage'

const ChangePaymentConfirmPage = () => {
    const navigate = useNavigate()
    const [ confirming, setConfirming ] = React.useState(false)
    const [ searchParams, ] = useSearchParams()

    const paymentIntent = searchParams.get('payment_intent')

    React.useEffect(() => {
        if (!confirming) {
            setConfirming(true)
            server.finishChangePayment().finally(() => {
                setConfirming(false)
                navigate('/app/billing')
            })
        }
    }, [ paymentIntent , confirming, setConfirming, navigate ])
    
    return (
        <CenterVertical className="mt-5" width="sm">
            <div className="row justify-content-center py-5 px-3">
                <div className="col">
                    <h2 className="mb-4">{ i18n.t('Confirming Payment Method...') }</h2>
                        <LoadingPage />
                </div>
            </div>
        </CenterVertical>
    )
}

export default ChangePaymentConfirmPage
