/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2023 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../i18n'

import React from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

//const stripePromise = loadStripe('pk_test_51MwP6bBZoMPjh1DY3Y3CJ16Uyb03jWqZSAYjc9JcsBoSisrNh7pl8PNRbRMj7KNmmdfQQy2deI7Y1olAo3rcsdJ5002uHwU6kX')
const stripePromise = loadStripe('pk_live_51N0RbUGo8ZyIHcvNt265yrVWTv85czxa9OU5ofqplla1Z987vze6Sz2IYXM52S6f2JylVq4kyKjjT8pzJcfEjvfk00CN1QbCN6')

const appearance = {}

const CheckoutForm = ({ disabled, setupIntent, returnUrl='https://app.digi-cards.biz', ...props}) => {
    const stripe = useStripe()
    const elements = useElements()
    const [ submitting, setSubmitting ] = React.useState(false)
    const [ errorMsg, setErrorMsg ] = React.useState(null)

    const onSubmit = async (ev) => {
        ev.preventDefault()
        setSubmitting(true)

        const apiMethod = setupIntent ? stripe.confirmSetup : stripe.confirmPayment

        const result = await apiMethod({
            elements,
            confirmParams: { return_url: returnUrl },
        })
      
        if (result.error) {
            console.log(result.error)
            setErrorMsg(result.error.message)
        }

        setSubmitting(false)
    }

    return (
        <div { ...props }>
            <PaymentElement />
            { errorMsg && <Alert className="mt-3" variant="danger">{ errorMsg }</Alert> }
            <Button
                className="mt-3"
                disabled={!stripe || submitting || disabled}
                onClick={onSubmit}
                >
                { i18n.t('Submit') }
                { submitting && <>&nbsp;<Spinner animation="border" role="status" size="sm"/></>}
            </Button>
        </div>
    )
}

export const PaymentForm = ({ clientSecret, ...props }) => (
    <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
        <CheckoutForm { ...props } />
    </Elements>
)
