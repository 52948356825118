/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import React from 'react'
import { useParams } from 'react-router-dom'

import { useCard } from '../core/server'
import { CenterVertical } from '../widgets/layouts'
import QRCode from '../widgets/QRCode'

const CardQRPage = () => {
    const { cardId } = useParams()
    const { card } = useCard(cardId)

    const link = `https://${window.location.host}/app/c/${cardId}`

    return (
        <CenterVertical className="mt-3" width="sm">
            <div className="d-flex flex-column align-items-center py-5">
                <QRCode value={link} logo={card?.values?.qrlogo} />
            </div>
        </CenterVertical>
    )
}

export default CardQRPage
