/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

//import i18n from '../i18n'

import React from 'react'
import { useParams } from 'react-router-dom'

import { useCard } from '../core/server'

import { getTemplate } from '../widgets/templates'
import { Spinner } from '../widgets/uielements'

const CardViewPage = () => {
    const { cardId } = useParams()
    const { isLoading, card } = useCard(cardId)

    return (
        <div className="CardViewPage">
            { isLoading ? (
                <Spinner />
            ) : (!!card && (
                getTemplate(card.template).render(cardId, card.values)
            ))}
        </div>
    )
}

export default CardViewPage
