/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import plans from '../../core/plans'

import CardStandard from './CardStandard'
import CardTraditional from './CardTraditional'

const templates = {
    standard: new CardStandard(),
    traditional: new CardTraditional(),
}

export default templates

export const getTemplate = name => (templates[name] || templates.standard)

const allKeys = Object.keys(templates)

export const templateChoices = allKeys.map(key => ([ key, templates[key].name ]))

export const templateChoicesByPlan = {}
plans.forEach(({ code, allowTemplates=allKeys }) => {
    templateChoicesByPlan[code] = allowTemplates.map(key => ([ key, templates[key].name ]))
})

export const templateMinChoices = [[ 'traditional', templates.traditional.name ]]
