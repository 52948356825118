/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../i18n'

import React from 'react'
import Alert from 'react-bootstrap/Alert'

import { CenterVertical } from '../widgets/layouts'
import { LinkButton } from '../widgets/router'

const ResetPasswordSuccessPage = () => (
    <CenterVertical className="mt-5" width="xs">
        <div className="row justify-content-center py-5 px-3">
            <div className="col text-center">
                <img className="mb-3" src="/app/icon256.png" width="128" height="128" alt="Logo"/>
                <Alert variant="success">{ i18n.t('Password reset successfully!') }</Alert>
                <br />
                <LinkButton href="..">{ i18n.t('Login') }</LinkButton>
            </div>
        </div>
    </CenterVertical>
)

export default ResetPasswordSuccessPage
