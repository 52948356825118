/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import useSWR from 'swr'

const apiPath = api => `/api/v1/app${api}`

const serverFetch = (api, ...args) => {
    console.log('GET', api)
    return fetch(apiPath(api), ...args).then(res => {
        if (res.status === 403) {
            return { error: 403, errdata: null } // don't retry 403
        }

        const data = res.json()
        if (data?.error) {
            console.warn('API error', api, data.error, data.errdata)
        }
        return data
    })
}

function getCookie(name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
    if (match) {
        return match[2]
    }
}

export const serverPost = (api, params={}, files, options={}) => {
    console.log('POST', api)

    let headers = { 'X-CSRFToken': getCookie('csrftoken') }
    let body

    if (files) {
        body = new FormData()
        body.append('jsonparams', JSON.stringify(params))
        for (const key in files) {
            body.append(key, files[key])
        }
    } else {
        body = JSON.stringify(params)
        headers['content-type'] = 'application/json'
    }

    return fetch(apiPath(api), {
        method: 'POST',
        body,
        headers,
        credentials: 'same-origin',
        cache: 'no-cache',
        redirect: 'follow',
        ...options,
    }).then(resp => {
        if (resp.status === 403) {
            throw new Error('Permission denied')
        } else if (resp.status !== 200) {
            throw new Error('Bad response: '+resp.status)
        }
        return resp.json()
    }).then(data => {
        if (data.error) {
            throw data
        }
        return data
    })
}

const useApi = (dataKey, api, options={}) => {
    const { data, error } = useSWR(api, serverFetch, options)
    const errorData = error || data?.error
    return {
        [dataKey]: errorData ? null : data,
        isLoading: !error && !data,
        isError: !!errorData,
        error: errorData,
    }
}

const cardKey = (cardId) => `/card/get?id=${cardId}`

export const useProfile = () => useApi('user', '/profile')
export const useSubscription = () => useApi('subscription', '/billing/getplan')
export const useCardList = () => useApi('data', '/card/list')
export const useCard = (cardId) => useApi('card', cardKey(cardId))

const setKey = (key, mutate) => (data => mutate(key, data))
const clearKey = (key, mutate) => (() => mutate(key, null))

const server = {
    register: (params, mutate) => (
        serverPost('/user/register', params).then((data) => {
            mutate('/profile', data.profile)
            mutate('/billing/getplan', data.subscription)
            return data
        })
    ),

    login: (params, mutate) => (
        serverPost('/user/login', params).then(setKey('/profile', mutate))
    ),

    logout: (mutate) => serverPost('/user/logout').then(() => {
        mutate(() => true, undefined, { revalidate: false }) // clear all cache
        mutate('/profile', null) // revalidate profile
    }),

    updateProfile: (params, mutate) => (
        serverPost('/profile/update', params).then(setKey('/profile', mutate))
    ),

    resetPassword: (params) => serverPost('/user/reset-password', params),
    resetPasswordConfirm: (params) => serverPost('/user/reset-confirm', params),

    changePassword: (params) => serverPost('/user/change-password', params),
    deleteAccount: (mutate) => (
        serverPost('/user/delete').then(clearKey('/profile', mutate))
    ),

    listCards: () => (
        serverPost('/card/list')
    ),
    createCard: (params, files, mutate) => (
        serverPost('/card/create', params, files).then((data) => {
            mutate(cardKey(data.id), data)
            mutate('/card/list', null)
        })
    ),
    updateCard: (params, files, mutate) => (
        serverPost('/card/update', params, files).then((data) => {
            mutate(cardKey(data.id), data)
            mutate('/card/list', null)
        })
    ),
    deleteCard: (params, mutate) => (
        serverPost('/card/delete', params).then(() => {
            mutate(cardKey(params.id), null)
            mutate('/card/list', null)
        })
    ),

    startChangePayment: () => serverPost('/billing/changepayment'),
    finishChangePayment: () => serverPost('/billing/changepayment/confirm'),
    changePlan: (params, mutate) => (
        serverPost('/billing/setplan', params).then((data) => {
            mutate('/billing/getplan', data)
            return data
        })
    ),

    logError: (title, msg) => serverPost('/misc/log', { title, msg }),
}

export default server