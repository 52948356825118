/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../i18n'

import React from 'react'
import { useNavigate } from 'react-router-dom'

import serverapi from '../core/server'
import validators from '../core/validators'
import { Form, TextField, Submit } from '../widgets/forms'
import { useGlobals } from '../core/globals'
import { CenterVertical } from '../widgets/layouts'
import { LinkButton } from '../widgets/router'

const onValidate = values => {
    const errors = {}

    if (!values.code) {
        errors.code = i18n.t('Please enter reset code')
    }
    if (!validators.password(values.password)) {
        errors.password = i18n.t('Password must contain at least 8 characters and include letters and numbers')
    }
    else if (values.password !== values.confirmPassword) {
        errors.confirmPassword = i18n.t('Passwords do not match')
    }

    return errors
}

const ResetPasswordConfirmPage = () => {
    const navigate = useNavigate()
    const { resetEmail, setGlobals } = useGlobals()

    if (!resetEmail) {
        navigate('..')
        return
    }

    const onSubmit = ({ values, setSubmitting, setErrors }) => {
        const params = { email: resetEmail, code: values.code, password: values.password }

        setSubmitting(true)
        serverapi.resetPasswordConfirm(params).then(() => {
            setGlobals({ resetEmail: null })
            navigate('../reset-password-success')
        }).catch(() => {
            setErrors({ code: i18n.t('Invalid reset code')})
        }).finally(() => {
            setSubmitting(false)
        })
    }

    return (
        <CenterVertical className="mt-5" width="xs">
            <div className="row justify-content-center py-5 px-3">
                <div className="col text-center">
                    <img className="mb-3" src="/app/icon256.png" width="128" height="128" alt="Logo"/>
                    <Form initialValues={{ code: '', password: '', confirmPassword: '' }} onValidate={onValidate} onSubmit={onSubmit}>
                        <TextField name="code" type="text" placeholder={i18n.t('Reset Code')} autoFocus />
                        <TextField name="password" type="password" placeholder={i18n.t('Password')} />
                        <TextField name="confirmPassword" type="password" placeholder={i18n.t('Confirm Password')} />
                        <Submit className="w-100 mb-4">{ i18n.t('Reset Password') }</Submit>
                        <LinkButton variant="link" size="sm" href="..">{ i18n.t('Cancel') }</LinkButton>
                    </Form>
                </div>
            </div>
        </CenterVertical>
    )
}


export default ResetPasswordConfirmPage
