/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../i18n'

import React from 'react'
import { useSWRConfig } from 'swr'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import serverapi from '../core/server'

import { CenterVertical } from '../widgets/layouts'
import CardList from '../widgets/CardList'

const HomePage = () => {
    const { mutate } = useSWRConfig()
    const [ deleteCard, setDeleteCard ] = React.useState(null)

    const closeDeleteModal = () => setDeleteCard(null)

    const onDelete = () => {
        serverapi.deleteCard({ id: deleteCard.id }, mutate).finally(closeDeleteModal)
    }

    return (
        <>
            <CenterVertical className="mt-3 p-3" width="lg">
                <CardList onDelete={setDeleteCard} />
            </CenterVertical>

            <Modal show={!!deleteCard} onHide={closeDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{ i18n.t('Please Confirm') }</Modal.Title>
                </Modal.Header>
                <Modal.Body>{ i18n.t('You are about to permenently delete ') + deleteCard?.name + '.  ' + i18n.t('Are you sure?') }</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteModal}>{ i18n.t('Cancel') }</Button>
                    <Button variant="danger" onClick={onDelete}>{ i18n.t('Yes, Delete') }</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default HomePage
