/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2023 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../i18n'

import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import Badge from 'react-bootstrap/Badge'
import Placeholder from 'react-bootstrap/Placeholder'

import { planByCode } from '../core/plans'
import { useSubscription } from '../core/server'

const SubscriptionWidget = ({ showUpgradeLink }) => {
    const { subscription } = useSubscription()
    const navigate = useNavigate()

    if (subscription?.billingStatus === 3) {
        return null // bypass
    }

    const plan = planByCode[subscription?.billingPlan]
    const badgeProps = plan?.badgeProps || { bg: 'light', text: 'dark' }

    return (
        <>
            { plan ? (
                <Badge className="pointer" { ...badgeProps } onClick={() => navigate('/app/billing')}>
                    { plan.label }
                </Badge>
            ) : (
                <Placeholder as="p" animation="glow">
                    <Placeholder xs={4} />
                </Placeholder>
            )}

            { showUpgradeLink && plan?.upgradable && (
                <Link className="ms-2" to="/app/billing/changeplan"><small>{ i18n.t('Upgrade') }</small></Link>
            )}
        </>
    )
}

export default SubscriptionWidget
