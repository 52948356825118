/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import { Outlet } from 'react-router-dom'

const PublicRootLayout = () => (
    <div className="main-public">
        <Outlet />
    </div>
)

export default PublicRootLayout
