/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../i18n'

import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSWRConfig } from 'swr'
import Button from 'react-bootstrap/Button'

import serverapi, { useProfile, useCard } from '../core/server'
import { extractFiles } from '../core/utils'

import { Form, TextField, ChoiceField, ImageField, useForm, Submit } from '../widgets/forms'
import { CenterVertical } from '../widgets/layouts'
import QRCode from '../widgets/QRCode'
import { getTemplate, templateChoices, templateChoicesByPlan, templateMinChoices } from '../widgets/templates'
import { Spinner } from '../widgets/uielements'

const TemplateSelect = () => {
    const { user } = useProfile()
    const { values, setValues } = useForm()

    const { name, template, ...cardValues } = values

    const onChange = (ev) => {
        const template = ev.target.value
        setValues({ name, template, ...getTemplate(template).getValues(cardValues)})
    }

    let choices
    if (user?.billingStatus === 3) {
        choices = templateChoices
    } else {
        choices = templateChoicesByPlan[user?.billingPlan] || templateMinChoices
    }

    return (
        <ChoiceField name="template" label={ i18n.t('Template') } choices={choices} onChange={onChange} />
    )
}

const QRFields = () => {
    const { values } = useForm()

    return (
        <>
            <ImageField name="qrlogo" label={ i18n.t('Custom QR Logo') } width={64} height={64}/>
            <div className="text-center">
                <h6>{ i18n.t('Preview') }</h6>
                <QRCode value="https://digi-cards.biz" logo={values.qrlogo} size={128}/>
            </div>
        </>
    )
}


const SaveCancelButtons = (props) => {
    const navigate = useNavigate()
    return (
        <div { ...props }>
            <Button variant="outline-primary" onClick={() => navigate(-1)}>{ i18n.t('Cancel') }</Button>&nbsp;
            <Submit>{ i18n.t('Save') }</Submit>
        </div>
    )
}

const CardEditForm = ({ cardId }) => {
    const { values } = useForm()
    const { name, loading, template, ...cardValues } = values

    const t = getTemplate(template)

    return loading ? (
        <Spinner />
    ) : (
        <div className="row">
            <div className="col-lg-6">
                <TextField name="name" type="text" label={ i18n.t('Card Name') } />
                <TemplateSelect />

                { t.editFields() }

                <QRFields />

                <SaveCancelButtons className="my-3 float-end" />
            </div>
            <div className="col-lg-6">
                <div className="card-preview">
                    <h4>{ i18n.t('Card Preview') }</h4>
                    { t.render(cardId, cardValues) }
                </div>

                { false && <SaveCancelButtons className="d-block d-lg-none my-3 float-end" /> }
            </div>
        </div>
    )
}

const CardLoader = ({ cardId }) => {
    const { isLoading, card } = useCard(cardId)
    const [ loadedCardId, setLoadedCardId ] = React.useState(null)
    const { setValues } = useForm()

    React.useEffect(() => {
        if (isLoading) {
            setValues({ loading: true })
        } else if (card && card.id !== loadedCardId) {
            setValues({
                name: card.name,
                template: card.template,
                loading: false,
                ...getTemplate(card.template).getValues(card.values),
            })
            setLoadedCardId(card.id)
        }
    }, [ isLoading, card, loadedCardId, setLoadedCardId, setValues ])

    return null
}

const getInitialValues = (user) => {
    const template = user.billingPlan === 'starter' ? 'traditional' : 'standard'
    return {
        name: '',
        template,
        loading: false,
        ...getTemplate(template).getValues({}),
    }
}

const CardEditPage = () => {
    const { mutate } = useSWRConfig()
    const { cardId } = useParams()
    const navigate = useNavigate()
    const { user } = useProfile()
    const [ initialValues, setInitialValues ] = React.useState(null)

    React.useEffect(() => {
        setInitialValues(getInitialValues(user))
    }, [ user, cardId ])

    const onSubmit = ({ values, setSubmitting }) => {
        let { name, loading, template, ...cardValues } = values

        const fileFields = getTemplate(template).fileFields.concat([ 'qrlogo' ])
        let files
        [ cardValues, files ] = extractFiles(cardValues, fileFields)

        const params = { name, template, values: cardValues }
        if (cardId) {
            params.id = cardId
        }

        setSubmitting(true)

        const api = cardId ? serverapi.updateCard : serverapi.createCard
        api(params, files, mutate).then(() => {
            navigate(-1)
        }).finally(() => {
            setSubmitting(false)
        })
    }

    return (
        <CenterVertical className="CardEditPage mt-3 p-3" width="lg">
            { initialValues && (
                <Form initialValues={initialValues} onSubmit={onSubmit}>
                    { cardId && <CardLoader cardId={cardId} /> }
                    <CardEditForm cardId={cardId} />
                </Form>
            )}
        </CenterVertical>
    )
}

export default CardEditPage
