/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import React from 'react'

const GlobalContext = React.createContext({})

export class GlobalContextProvider extends React.Component {
    constructor(props) {
        super(props)

        this.setGlobals = (values) => this.setState(values)
        this.state = { setGlobals: this.setGlobals }
    }

    render() {
        return (
            <GlobalContext.Provider value={this.state}>
                { this.props.children }
            </GlobalContext.Provider>
        )
    }
}

export const useGlobals = () => React.useContext(GlobalContext)
