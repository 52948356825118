/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2023 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../i18n'

const plans = [{
    code: 'starter',
    label: i18n.t('Starter'),
    desc: i18n.t('Access to the basic card template'),
    price: '$9.99 + $0.99/month',
    badgeProps: { bg: 'light', text: 'dark' },
    upgradable: true,
    allowTemplates: [ 'traditional' ]
}, {
    code: 'pro',
    label: i18n.t('Pro'),
    desc: i18n.t('Access to all card templates and fields'),
    price: '$24.99 + $4.99/month',
    badgeProps: { bg: 'primary' },
}]

export const planByCode = {}
plans.forEach(plan => { planByCode[plan.code] = plan })

export default plans
