/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import { Outlet } from 'react-router-dom'

import Footer from './Footer'
import TopNav from './TopNav'

const AppRootLayout = () => (
    <>
        <TopNav />
        <div className="main">
            <Outlet />
        </div>
        <Footer />
    </>
)

export default AppRootLayout;
