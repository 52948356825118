/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../i18n'

import React from 'react'
import { useSWRConfig } from 'swr'
import { useNavigate, Link } from 'react-router-dom'

import plans from '../core/plans'
import server, { useProfile } from '../core/server'
import { Form, Submit } from '../widgets/forms'
import { CenterVertical } from '../widgets/layouts'
import PlanSelectField from '../widgets/PlanSelectField'
import SubscriptionWidget from '../widgets/SubscriptionWidget'
import LoadingPage from '../widgets/LoadingPage'

const ChangePlanPage = () => {
    const { mutate } = useSWRConfig()
    const navigate = useNavigate()
    const { user, isLoading } = useProfile()

    const onSubmit = ({ values, setSubmitting }) => {
        setSubmitting(true)
        server.changePlan(values, mutate).catch(err => {
            console.log(err)
        }).finally(() => {
            navigate(-1)
            setSubmitting(false)
        })
    }

    const plan = plans.find(x => user?.billingPlan === x.code)

    if (isLoading) {
        return <LoadingPage />
    }
    if (!plan) {
        navigate(-1)
        return null
    }

    return (
        <CenterVertical className="mt-5" width="sm">
            <div className="row py-5 px-3">
                <div className="col">
                    <h2 className="mb-4">{ i18n.t('Select New Plan') }</h2>
                    <p>{ i18n.t('Current Plan') }: <SubscriptionWidget /></p>
                    <Form initialValues={{ plan: plan.code }} onSubmit={onSubmit}>
                        <PlanSelectField name="plan" />
                        <div className="d-flex flex-row-reverse">
                            <Submit>{ i18n.t('Select Plan') }</Submit>
                            <Link className="btn btn-outline-primary me-2" to={-1}>{ i18n.t('Cancel') }</Link>
                        </div>
                    </Form>
                </div>
            </div>
        </CenterVertical>
    )
}

export default ChangePlanPage
