/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2023 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import Alert from 'react-bootstrap/Alert'

import serverapi from './server'

const ErrorMessage = () => (
    <div style={{ width: 640, padding: 15 }}>
        <Alert variant="danger">
            Whoops, something went wrong.  Please hold while we recalibrate...
        </Alert>
    </div>
)

const ErrorHandler = ({ children }) => (
    <ErrorBoundary
        FallbackComponent={ErrorMessage}
        onError={(error, { componentStack }) => {
            const now = new Date().getTime()
            const stamp = localStorage.getItem('errorStamp')
            if (!stamp || (now - stamp) > 60000) {
                localStorage.setItem('errorStamp', now)
                serverapi.logError(error.message || 'Unknown error', componentStack).finally(() => {
                    window.location.reload()
                })
            } else {
                const errorCount = localStorage.getItem('errorCount') || 0
                if (errorCount < 3) {
                    localStorage.setItem('errorCount', errorCount + 1)
                    setTimeout(() => window.location.reload(), 5000)
                }
            }
        }}
        >
        { children }
    </ErrorBoundary>
)

export default ErrorHandler
