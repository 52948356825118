/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../i18n'

import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import serverapi from '../core/server'
import { Form, TextField, Submit } from './forms'

const onValidate = values => {
    const errors = {}
    if (!values.oldPassword) {
        errors.oldPassword = i18n.t('Please enter your old password')
    }
    if (!values.newPassword) {
        errors.newPassword = i18n.t('Please enter a new password')
    }
    else if (values.newPassword !== values.confirmPassword) {
        errors.confirmPassword = i18n.t('Passwords do not match')
    }
    return errors
}

const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
}

const ChangePasswordDialog = ({ isOpen, onComplete }) => {
    const onSubmit = ({ values, setSubmitting, setErrors }) => {
        setSubmitting(true)
        serverapi.changePassword(values).then(() => {
            onComplete(true)
        }).catch(err => {
            if (err.error === 2) {
                setErrors({ oldPassword: i18n.t('Incorrect password') })
            } else if (err.error === 1001) {
                setErrors({ newPassword: i18n.t('Password too weak') })
            }
        }).finally(() => {
            setSubmitting(false)
        })
    }

    const onCancel = () => onComplete(false)

    return (
        <Modal show={isOpen} onHide={onCancel}>
            <Form initialValues={initialValues} onValidate={onValidate} onSubmit={onSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>{ i18n.t('Change Password') }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TextField name="oldPassword" type="password" label={i18n.t('Old Password')} />
                    <TextField name="newPassword" type="password" label={i18n.t('New Password')} />
                    <TextField name="confirmPassword" type="password" label={i18n.t('Confirm New Password')} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={onCancel}>{ i18n.t('Cancel') }</Button>
                    <Submit>{ i18n.t('Change Password') }</Submit>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default ChangePasswordDialog
