/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../../i18n'

//import Accordion from 'react-bootstrap/Accordion'

import { joinStrings, imageUrl } from '../../core/utils'
import { ColorField, ImageField } from '../forms'

import BaseCard, { Row } from './BaseCard'
import ShareButtons from './ShareButtons'

const phoneString = (values) => {
    let phone = []
    if (values.mobilephone) {
        phone.push(i18n.t('cell')+': ' + values.mobilephone)
    }
    if (values.officephone) {
        phone.push(i18n.t('work')+': ' + values.officephone)
    }
    phone = phone.join(' | ')
    return phone
}

class CardStandard extends BaseCard {
    name = i18n.t('Pro')

    templateFields = {
        backgroundImage: '/app/img/landscape.jpeg',
        logo: '/app/img/logo-placeholder.png',
        backgroundColor: '#000000',
    }

    fileFields = [ 'backgroundImage', 'logo' ]

    textPreview(cardValues) {
        const values = this.getValues(cardValues)

        const orgString = joinStrings([ values.organization, values.title ], ' | ')

        return (
            <>
                { orgString && <span>{ orgString }</span> }
                { values.fullname && <span>{ values.fullname }</span> }
                { values.address && <span>{ values.address }</span> }
                { values.email && <span>{ values.email }</span> }
            </>
        )
    }

    editFields() {
        return (
            <>
                <Row>
                    <ImageField name="logo" label={ i18n.t('Main Photo') } width={168} height={168}/>
                </Row>
                { super.editFields() }
                <Row>
                    <ImageField name="backgroundImage" label={ i18n.t('Header Image') } width={500} height={190}/>
                </Row>
                <Row>
                    <ColorField name="backgroundColor" label={ i18n.t('Background Color') } />
                </Row>
            </>
        )
    }

    render(cardId, cardValues) {
        const values = this.getValues(cardValues)

        const phoneLine = phoneString(values)
        const orgString = joinStrings([ values.title || i18n.t('Title'), values.organization ], ' | ')

        return (
            <div className="CardStandard" style={{ backgroundColor: values.backgroundColor }}>
                <div className="header-image" style={{ backgroundImage: `url(${imageUrl(values.backgroundImage)})` }} />
                <div className="profile-image">
                    <img src={ imageUrl(values.logo) } alt="logo"/>
                </div>
                <div className="text-center px-3 mb-3">
                    <h3>{ values.fullname || i18n.t('Full Name') }</h3>
                    <p>{ orgString }</p>
                    { values.email && <p>{ i18n.t('email') }: { values.email }</p> }
                    { phoneLine.length > 0 && <p>{ phoneLine }</p> }
                    { values.address && <p>{ values.address }</p> }
                </div>

                <ShareButtons cardId={cardId} values={values} />
            </div>
        )
    }
}

export default CardStandard
