/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2023 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../i18n'

import React from 'react'
import Button from 'react-bootstrap/Button'

let pwaEvent

window.addEventListener("beforeinstallprompt", (event) => {
    console.log('PWA supported')
    pwaEvent = event
    pwaEvent.preventDefault()
    Array.prototype.forEach.call(
        document.getElementsByClassName('pwa-button'),
        (el) => { el.style.display = 'inline-block' }
    )
    Array.prototype.forEach.call(
        document.getElementsByClassName('pwa-fallback'),
        (el) => { el.style.display = 'none' }
    )
})

export const isPWASupported = () => !!pwaEvent

const InstallPWAButton = ({ title=i18n.t('Install'), launchUrl, children }) => (
    <>
        <Button
            className="pwa-button"
            style={{ display: pwaEvent ? 'inline-block' : 'none' }}
            variant="primary"
            onClick={() => {
                if (launchUrl) {
                    localStorage.setItem('launchUrl', launchUrl)
                }
                pwaEvent.prompt()
            }}
            >
            { title }
        </Button>
        <div className="pwa-fallback" style={{ display: pwaEvent ? 'none' : 'block' }}>
            { children }
        </div>
    </>
)

export default InstallPWAButton
