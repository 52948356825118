/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../../i18n'

import { joinStrings, imageUrl } from '../../core/utils'
import { ColorField, ImageField } from '../forms'

import BaseCard, { Row, InputField } from './BaseCard'
import ShareButtons from './ShareButtons'

class CardTraditional extends BaseCard {
    name = i18n.t('Traditional')

    templateFields = {
        logo: '',
        headercolor: '#00008B',
    }

    fileFields = [ 'logo' ]

    textPreview(cardValues) {
        const values = this.getValues(cardValues)

        const title = joinStrings([ values.fullname, values.title ], ', ')

        return (
            <>
                { title && <span className="cardName">{ title }</span> }
                { values.officephone && <span className="cardAddress">{ values.officephone }</span> }
                { values.address && <span className="cardAddress">{ values.address }</span> }
                { values.website && <span className="cardWebsite">{ values.website }</span> }
            </>
        )
    }

    editFields() {
        return (
            <>
                <Row>
                    <ImageField name="logo" label={ i18n.t('Logo') } width={300} height={60} backgroundName="headercolor"/>
                </Row>
                <Row>
                    <ColorField name="headercolor" label={ i18n.t('Header Background Color') } />
                </Row>
                <Row>
                    <InputField size={6} name="firstname" label={ i18n.t('First') } />
                    <InputField size={6} name="middlename" label={ i18n.t('Middle') } />
                </Row>
                <Row>
                    <InputField size={6} name="lastname" label={ i18n.t('Last') } />
                    <InputField size={6} name="title" label={ i18n.t('Title') } />
                </Row>
                <Row>
                    <InputField size={6} name="email" label={ i18n.t('Email') } type="email" />
                    <InputField size={6} name="officephone" label={ i18n.t('Office Phone') } type="tel" />
                </Row>
                <Row>
                    <InputField size={12} name="street" label={ i18n.t('Address') } />
                </Row>
                <Row>
                    <InputField size={6} name="city" label={ i18n.t('City') } />
                    <InputField size={3} name="region" label={ i18n.t('State') } />
                    <InputField size={3} name="zipcode" label={ i18n.t('ZIP Code') } />
                </Row>
                <Row>
                    <InputField size={12} name="website" label={ i18n.t('Company Website') } />
                </Row>
            </>
        )
    }

    render(cardId, cardValues) {
        const values = this.getValues(cardValues)
        const padleft = 50
        const itemY = 400, itemLineHeight = 64

        let title = values.fullname
        if (values.title) {
            title += ', ' + values.title
        }

        return (
            <div className="d-flex flex-column align-items-center">
                <svg className="m-2 shadow" version="1.1" viewBox="0 0 1000 640" xmlns="http://www.w3.org/2000/svg">
                    <style>
                        {
                            `text.header {
                                font-size: 80px;
                                fill: black;
                            }
                            text.text {
                                font-size: 50px;
                                fill: black;
                                font-weight: 300;
                            }`
                        }
                    </style>
                    <rect width="1000" height="175" fill={ values.headercolor } />
                    { values.logo && <image href={ imageUrl(values.logo) } x={ padleft } y="25" height="125"/> }
                    <text className="header" x={ padleft } y="300">{ title }</text>
                    <text className="text" x={ padleft } y={ itemY + 0*itemLineHeight }>o: { values.officephone }</text>
                    <text className="text" x={ padleft } y={ itemY + 1*itemLineHeight }>e: { values.email }</text>
                    <text className="text" x={ padleft } y={ itemY + 2*itemLineHeight }>w: { values.website }</text>
                    <text className="text" x={ padleft } y={ itemY + 3*itemLineHeight }>a: { values.address }</text>
                </svg>

                <div className="mt-5">
                    <ShareButtons cardId={cardId} values={values} />
                </div>
            </div>

        )
    }
}

export default CardTraditional
