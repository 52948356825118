/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import React from 'react'
import ReactSpinner from 'react-bootstrap/Spinner'

export const Spinner = () =>  (
    <ReactSpinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
    </ReactSpinner>
)
