/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2023 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../i18n'

import React from 'react'
import classNames from 'classnames'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'

import { useField } from './forms'
import plans from '../core/plans'

const PlanWidget = ({ code, label, desc, price, onSelect, selected }) => (
    <Card className={classNames('PlanWidget', { selected, shadow: selected })} onClick={() => onSelect(code)}>
        <Card.Body>
            <Card.Title>{ label }</Card.Title>
            <Card.Text>{ desc }</Card.Text>
            <Button variant="primary" onClick={onSelect}>{ i18n.t('Select') }</Button>
        </Card.Body>
        <Card.Footer className="fw-bold">{ price }</Card.Footer>
    </Card>
)

const PlanSelectField = ({ name }) => {
    const { value, setValue, error } = useField(name)

    return (
        <div className="PlanSelectField row g-3 mb-3">
            { plans.map(plan => (
                <div className="col-6" key={`plan-${plan.code}`}>
                    <PlanWidget onSelect={setValue} selected={plan.code === value} { ...plan } />
                </div>
            ))}
            { error && <div className="text-danger"><small>{ error }</small></div> }
        </div>
    )
}

export default PlanSelectField
