/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2023 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import React from 'react'
import ReactQRCode from 'react-qr-code'

import { imageUrl } from '../core/utils'

const QRCode = ({ value, logo, ...props }) => (
    <div className="qrcode">
        <ReactQRCode value={value} level={logo ? 'Q' : 'L'} { ...props } />
        { logo && <img src={imageUrl(logo)} alt="QR Logo" /> }
    </div>
)

export default QRCode
