/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../i18n'

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSWRConfig } from 'swr'
import Button from 'react-bootstrap/Button'

import serverapi, { useProfile } from '../core/server'
import { Form, TextField, Submit } from '../widgets/forms'
import ChangePasswordDialog from '../widgets/ChangePasswordDialog'
import { CenterVertical } from '../widgets/layouts'
import { LinkButton } from '../widgets/router'
import SubscriptionWidget from '../widgets/SubscriptionWidget'
import { Spinner } from '../widgets/uielements'

const onValidate = values => {
    const errors = {}

    return errors
}

const initialValues = (user) => ({
    firstname: user.firstname,
    lastname: user.lastname,
})

const ProfilePage = () => {
    const { mutate } = useSWRConfig()
    const navigate = useNavigate()
    const { user, isLoading } = useProfile()
    const [ showCP, setShowCP ] = React.useState(false)

    const onSubmit = ({ values, setSubmitting, setErrors }) => {
        setSubmitting(true)
        serverapi.updateProfile(values, mutate).finally(() => {
            setSubmitting(false)
            navigate(-1)
        })
    }

    return (
        <CenterVertical className="mt-3" width="sm">
            <div className="row py-5 px-3">
                <div className="col">
                    <h2 className="mb-4">{ i18n.t('User Profile') }</h2>
                    { isLoading ? (
                        <Spinner />
                    ) : (
                        <Form initialValues={initialValues(user)} onValidate={onValidate} onSubmit={onSubmit}>

                            <div className="row g-3 mb-3">
                                <div className="col-6">Subscription</div>
                                <div className="col-6"><SubscriptionWidget showUpgradeLink /></div>
                            </div>

                            <TextField name="firstname" type="text" label={i18n.t('First Name')} />
                            <TextField name="lastname" type="text" label={i18n.t('Last Name')} />

                            <div className="text-center">
                                <LinkButton variant="outline-primary" href={-1}>{ i18n.t('Cancel') }</LinkButton>&nbsp;
                                <Submit>{ i18n.t('Update Profile') }</Submit>
                                <br /><br />
                                <Button variant="primary" onClick={() => setShowCP(true)}>{ i18n.t('Change Password') }</Button>
                            </div>
                        </Form>
                    )}
                </div>
            </div>

            <ChangePasswordDialog isOpen={showCP} onComplete={() => setShowCP(false)} />
        </CenterVertical>
    )
}

export default ProfilePage
