/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../i18n'

import React from 'react'
import { useSWRConfig } from 'swr'
import { Link } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import serverapi from '../core/server'

const TopNav = () => {
    const [ expanded, setExpanded ] = React.useState(false)
    const { mutate } = useSWRConfig()

    const logout = () => serverapi.logout(mutate)

    return (
        <div className="AppNav">
            <Navbar bg="light" expand="md" expanded={expanded}>
                <Navbar.Brand>
                    <Link to="/app">
                        <img className="navLogo" alt="Logo" src={'/app/logo192.png'}/>
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="home-navbar" onClick={() => setExpanded(expanded ? false : "expanded")} />
                <Navbar.Collapse id="home-navbar">
                    <Nav className="ms-auto">
                        <Nav.Item>
                            <Link to="/app" className="nav-link" onClick={() => setExpanded(false)}>{i18n.t('Home')}</Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to="/app/profile" className="nav-link" onClick={() => setExpanded(false)}>{i18n.t('Profile')}</Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to="/app/billing" className="nav-link" onClick={() => setExpanded(false)}>{i18n.t('Billing')}</Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#" onClick={logout}>{i18n.t('Log Out')}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default TopNav