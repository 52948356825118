/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../i18n'

import React from 'react'
import { useParams } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Collapse from 'react-bootstrap/Collapse'
import Overlay from 'react-bootstrap/Overlay'
import Tooltip from 'react-bootstrap/Tooltip'

import { useCard } from '../core/server'
import { isIOS, isSafari } from '../core/utils'
import InstallPWAButton from '../widgets/InstallPWAButton'
import { CenterVertical } from '../widgets/layouts'
import QRCode from '../widgets/QRCode'

const WallpaperInstructions = () => {
    const [ show, setShow ] = React.useState(false)

    if (!isIOS()) {
        return null
    }

    return (
        <div className="mt-3" style={{ fontSize: '0.8rem' }}>
            { !show && (
                <button className="btn btn-primary" onClick={() => setShow(true)}>{ i18n.t('Add to Home Screen') }</button>
            )}
            <Collapse in={show}>
                { isSafari() ? (
                    <div>
                        <h5>{ i18n.t('Add to Home Screen') }</h5>
                        <ol>
                            <li>
                                { i18n.t('Tap the') }&nbsp;
                                <img src="/app/img/safari-share.png" width="20" height="24" alt="Safari Share" />&nbsp;
                                { i18n.t('icon in the menu bar') }
                            </li>
                            <li>
                                { i18n.t('Scroll down the list of options, then tap Add to Home Screen')}
                            </li>
                        </ol>
                        <p>
                            { i18n.t('See') } <a href="https://support.apple.com/guide/iphone/bookmark-favorite-webpages-iph42ab2f3a7/ios#:~:text=in%20the%20menu%20bar.,tap%20Add%20to%20Home%20Screen." target="_blank" rel="noopener noreferrer">{ i18n.t('this page') }</a> { i18n.t('for more details.') }
                        </p>
                    </div>
                ) : (
                    <div>
                        <h5>{ i18n.t('Open in Safari') }</h5>
                        <p>
                            { i18n.t('To add a link to the QR code on your home screen, you need to first open this page in the Safari browser')}
                            &nbsp;<img src="/app/img/safari-icon.png" width="24" height="24" alt="Safari"/>
                        </p>
                    </div>
                )}
            </Collapse>
        </div>
    )
}

const CardSharePage = () => {
    const { cardId } = useParams()
    const { card } = useCard(cardId)
    const [ showCopied, setShowCopied ] = React.useState(false)
    const copyBtnRef = React.useRef(null)

    const link = `https://${window.location.host}/app/c/${cardId}`

    return (
        <CenterVertical className="mt-3" width="sm">
            <div className="d-flex flex-column align-items-center py-5">
                <QRCode value={link} logo={card?.values?.qrlogo} />

                <Button
                    ref={copyBtnRef}
                    className="my-3"
                    variant="primary"
                    disabled={showCopied}
                    onClick={() => {
                        navigator?.clipboard?.writeText(link)
                        setShowCopied(true)
                        setTimeout(() => setShowCopied(false), 2000)
                    }}
                    >
                    { i18n.t('Copy Link') }
                </Button>
                <Overlay target={copyBtnRef.current} show={showCopied} placement="right">
                    {(props) => (
                        <Tooltip {...props}>{ i18n.t('Copied!') }</Tooltip>
                    )}
                </Overlay>

                <InstallPWAButton title={ i18n.t('Add to Home Screen') } launchUrl={`/app/qr/${cardId}`}>
                    <WallpaperInstructions />
                </InstallPWAButton>
            </div>
        </CenterVertical>
    )
}

export default CardSharePage
