/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../../i18n'

import { makeUrl } from '../../core/utils'

const buttons = [
    { key: 'mobilephone', icon: 'fa-solid fa-mobile', href: (x) => `tel:${x}` },
    { key: 'officephone', icon: 'fa-solid fa-phone', href: (x) => `tel:${x}` },
    { key: 'email', icon: 'fa-solid fa-envelope', href: makeUrl },
    { key: 'website', icon: 'fa-solid fa-link', href: makeUrl },
    { key: 'website2', icon: 'fa-solid fa-link', href: makeUrl },
    { key: 'website3', icon: 'fa-solid fa-link', href: makeUrl },
    { key: 'linkedin', icon: 'fa-brands fa-linkedin', href: makeUrl },
    { key: 'instagram', icon: 'fa-brands fa-instagram', href: makeUrl },
    { key: 'facebook', icon: 'fa-brands fa-facebook', href: makeUrl },
    { key: 'twitter', icon: 'fa-brands fa-twitter', href: makeUrl },
    { key: 'youtube', icon: 'fa-brands fa-youtube', href: makeUrl },
    { key: 'tiktok', icon: 'fa-brands fa-tiktok', href: makeUrl },
]

const ShareButtons = ({ cardId, values }) => {
    const icons = buttons.filter(({ key }) => values[key]).map(({ key, icon, href }) => (
        { icon, href: href(values[key]) }
    ))

    return (
        <div className="ShareButtons">
            <div style={{ gridColumn: '1 / 4'}}>
                <a className="btn btn-primary text" href={cardId && `/cd/${cardId}`}>
                    { i18n.t('Save Contact') }
                </a>
            </div>
            { icons.map(({ icon, href, onClick }, i) => (
                <div key={'share'+i}>
                    <a  className="btn btn-primary icon" href={href} onClick={onClick}>
                        <i className={icon} />
                    </a>
                </div>
            ))}
        </div>
    )
}

export default ShareButtons
