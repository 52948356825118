/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import { BrowserRouter } from 'react-router-dom'

import ErrorHandler from './core/ErrorHandler'
import { GlobalContextProvider } from './core/globals'
import { useRoutes } from './core/routes'

const App = () => {
    const Routes = useRoutes()
    return (
        <GlobalContextProvider>
            <ErrorHandler>
                <BrowserRouter>
                    { Routes }
                </BrowserRouter>
            </ErrorHandler>
        </GlobalContextProvider>
    )
}

export default App
