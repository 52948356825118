/*-----------------------------------------------------------------------------
 *  The initial developer of the original code is Plastimold Products
 *
 *  Contains unpublished trade secrets of Plastimold Products
 *  Delray Beach, FL, USA.
 *
 *  (C) Copyright 2022 Plastimold Products
 *
 *             ALL RIGHTS RESERVED
 * ----------------------------------------------------------------------------
 */

import i18n from '../i18n'

import React from 'react'
import { Link } from 'react-router-dom'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'

import { planByCode } from '../core/plans'
import { useSubscription } from '../core/server'
import DeleteAccountDialog from '../widgets/DeleteAccountDialog'
import { CenterVertical } from '../widgets/layouts'
import { Spinner } from '../widgets/uielements'

const accountStatusProps = {
    1: [{ bg: 'warning', text: 'dark' }, i18n.t('Inactive')],
    2: [{ bg: 'success' }, i18n.t('Active')],
    3: [{ bg: 'light', text: 'dark' }, i18n.t('Bypass')],
}

const AccountStatus = ({ status }) => {
    const [ props, label ] = accountStatusProps[status] || []
    return props ? <Badge { ...props }>{ label }</Badge> : null
}

const billingStatusProps = {
    incomplete: [{ bg: 'warning', text: 'dark' }, i18n.t('Incomplete')],
    incomplete_expired: [{ bg: 'warning', text: 'dark' }, i18n.t('Incomplete')],
    trialing: [{ bg: 'light', text: 'dark' }, i18n.t('Trial')],
    active: [{ bg: 'success' }, i18n.t('Active')],
    past_due: [{ bg: 'warning', text: 'dark' }, i18n.t('Past Due')],
    canceled: [{ bg: 'warning', text: 'dark' }, i18n.t('Cancelled')],
    unpaid: [{ bg: 'warning', text: 'dark' }, i18n.t('Unpaid')],
}

const SubStatus = ({ status }) => {
    const [ props, label ] = billingStatusProps[status] || []
    return props ? <Badge { ...props }>{ label }</Badge> : null
}

const PaymentMethod = ({ brand, last4, expiration }) => {
    const label = {
        amex: 'American Express',
        diners: 'Diners',
        discover: 'Discover',
        eftpos_au: 'Eftpos',
        jcb: 'JCB',
        mastercard: 'Mastercard',
        unionpay: 'Union Pay',
        visa: 'VISA',
    }[brand]
    
    return label && (
        <span className="me-3">
            { label } ****{ last4 }
            { expiration && (
                <><br />{ i18n.t('Exp') }: { expiration }</>
            )}
        </span>
    )
}

const SubscriptionTable = ({ sub, setShowDelete }) => {
    if (!sub) {
        return null
    }

    const plan = planByCode[sub?.billingPlan]

    return (
        <div className="container">
            <div className="row mb-3">
                <div className="col-6">{ i18n.t('Account Status') }</div>
                <div className="col-6"><AccountStatus status={ sub.billingStatus } /></div>
            </div>
            { sub.billingStatus !== 3 && (
                <>
                    <div className="row mb-3">
                        <div className="col-6">{ i18n.t('Subscription Status') }</div>
                        <div className="col-6"><SubStatus status={ sub.status } /></div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">{ i18n.t('Plan') }</div>
                        <div className="col-6">{ plan && <Badge { ...plan.badgeProps }>{ plan.label }</Badge> }</div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">{ i18n.t('Price') }</div>
                        <div className="col-6">${ sub.price.toFixed(2) } / month</div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">{ i18n.t('Payment Method') }</div>
                        <div className="col-6">
                            { sub.paymentMethod && <PaymentMethod { ...sub.paymentMethod } /> }
                            <br/>
                            <Link to="/app/billing/payment"><small>{ i18n.t('Change') }</small></Link>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">{ i18n.t('Next Bill Date') }</div>
                        <div className="col-6">{ new Date(sub.nextBillDate * 1000).toLocaleDateString() }</div>
                    </div>
                    { plan.upgradable && (
                        <div className="row mb-3">
                            <div className="col-12">
                                <Link className="btn btn-primary" to="/app/billing/changeplan">{ i18n.t('Upgrade Subscription') }</Link>
                            </div>
                        </div>
                    )}
                    <div className="row mt-5">
                        <div className="col-12">
                            <Button variant="link" onClick={() => setShowDelete(true)}><small>{ i18n.t('Delete Account') }</small></Button>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

const BillingPage = () => {
    const { subscription, isLoading } = useSubscription()
    const [ showDelete, setShowDelete ] = React.useState(false)

    return (
        <CenterVertical className="mt-3" width="sm">
            <div className="row py-5 px-3">
                <div className="col">
                    <h2 className="mb-4">{ i18n.t('Billing') }</h2>
                    { isLoading ? <Spinner /> : <SubscriptionTable sub={subscription} setShowDelete={setShowDelete} /> }
                </div>
            </div>

            <DeleteAccountDialog isOpen={showDelete} onComplete={() => setShowDelete(false)} />
        </CenterVertical>
    )
}

export default BillingPage
